import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import Karta from "../components/Karta";
import SEO from "../components/SEO";
import BanerPageLayout from "../layout/BanerPageLayout";
import { FontSizeHeader, FontSizeLabel, Label } from "../styles/shared";
import getRichText from "../utils/getRichText";

const Text = styled.address`
  ${Label}
  ${FontSizeHeader}
  padding: 0.5em;
  font-style: normal;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    ${FontSizeLabel}
  }
`;

const GoogleMap = styled.iframe`
  width: 100%;
  height: 400px;
  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;

const Container = styled.article`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

const Widok = styled.div`
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-template-rows: 1fr;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-contet: center;
  }
`;

const GodzinyOtwarcia = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin: 1em;
  }
  @media screen and (max-width: 768px) {
    margin-top: 2em;
  }
`;

const Lokalizacja = () => {
  const {
    allContentfulStronaLokalizacja: {
      nodes: [nodes],
    },
  } = useStaticQuery(graphql`
    query {
      allContentfulStronaLokalizacja {
        nodes {
          baner {
            gatsbyImageData
            description
            contentful_id
            __typename
          }
          seoOpisKarty
          seoTytulKarty
          googleMapsIframe {
            googleMapsIframe
          }
          adres
          godzinyOtwarcia {
            raw
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <SEO {...nodes} />
      <BanerPageLayout baner={nodes.baner}>
        <Karta>
          <Text>{nodes.adres}</Text>
          <Widok>
            <Container>
              <GoogleMap
                src={nodes.googleMapsIframe.googleMapsIframe}
                allowFullScreen
                loading="lazy"
              ></GoogleMap>
            </Container>
            <GodzinyOtwarcia>
              {getRichText({ content: nodes.godzinyOtwarcia })}
            </GodzinyOtwarcia>
          </Widok>
        </Karta>
      </BanerPageLayout>
    </React.Fragment>
  );
};

export default Lokalizacja;
